var render = function () {
  var _vm$data, _vm$data2, _vm$data2$category, _vm$data3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('nav', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _vm.data ? _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'e-book'
      }
    }
  }, [_vm._v("E-book")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.name))])], 1)], 1), _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "xl": "8"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent bg-lighten-4",
    staticStyle: {
      "background-color": "transparent",
      "box-shadow": "none"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', {
    staticStyle: {
      "font-size": "1rem"
    }
  }, [_c('b-badge', {
    staticClass: "mr-75 float-left",
    attrs: {
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$category = _vm$data2.category) === null || _vm$data2$category === void 0 ? void 0 : _vm$data2$category.name) + " ")])], 1), _c('b-card-text', {
    staticClass: "text-dark"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('p', {
    staticClass: "text-dark mt-1",
    staticStyle: {
      "font-size": "1.25rem"
    }
  }, [_c('b', [_vm._v(_vm._s((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.name))])])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('p', {
    staticStyle: {
      "font-size": "1rem"
    }
  }, [_c('b', [_vm._v("Tentang Buku Ini")])])]), _c('div', {
    staticStyle: {
      "text-align": "left"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.data.description)
    }
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_c('b-card', {
    staticClass: "text-left",
    attrs: {
      "no-body": ""
    }
  }, [_c('section', [_c('b-card-body', [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "bg-light-secondary mb-1"
  }, [_c('b-img', {
    staticClass: "m-2",
    staticStyle: {
      "width": "60%"
    },
    attrs: {
      "src": _vm.apiFile + _vm.data.photo
    }
  })], 1)]), _c('b-card-title', {
    staticStyle: {
      "font-size": "1.25rem"
    }
  }, [_vm._v(" Harga ")]), _c('b-card-text', {
    staticClass: "text-primary"
  }, [_vm.data.price > 0 ? _c('span', {
    staticStyle: {
      "font-size": "1.75rem"
    }
  }, [_c('b', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.data.price)))])]) : _c('span', {
    staticStyle: {
      "font-size": "1.75rem"
    }
  }, [_c('b', [_vm._v("Gratis")])])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('p', {}, [_c('b-badge', {
    attrs: {
      "variant": "light-secondary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v(" Terbit " + _vm._s(_vm.data.publication_year) + " ")])], 1), _c('b-badge', {
    staticClass: "ml-25",
    attrs: {
      "variant": "light-secondary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v(_vm._s(_vm.data.num_page) + " Halaman")])], 1)], 1)]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm.data.price > 0 && _vm.isSiswaMember ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.saveEbook
    }
  }, [_vm._v("Tambahkan")]) : _vm.data.price > 0 && !_vm.isSiswaMember ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "to": {
        name: 'payment-ebook',
        params: {
          id: _vm.data.slug
        }
      }
    }
  }, [_vm._v(" Beli ")]) : _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.saveEbook
    }
  }, [_vm._v(" Tambahkan ")])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)]) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "py-4 mb-4"
  }, [_c('img', {
    staticClass: "text-center",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "src": "/notfound.svg",
      "alt": ""
    }
  }), _c('h3', {
    staticClass: "text-center mt-2 mb-1"
  }, [_vm._v("E-Book Tidak Ditemukan")]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push('/e-book');
      }
    }
  }, [_vm._v("Kembali")])], 1)])]), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }